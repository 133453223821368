<template>
    <div class="scenery-more">
        <slideshow :slideshowList="slideshowList" @gotodetail="handleGo" ></slideshow>
        <img class="debris" src="../../static/scenery/debris.png" />
        <div class="scenery-content">
            <div class="title">
                
                <router-link @click.native="$store.commit('active', 0)" to="/">
                    <span style="margin: 0 6px;color: #303133;font-weight: 700;"> <img
                      class="home-icon"
                      src="~static/scenery/home.png"
                      alt=""
                      style="width: 20; height: 20"
                    />
                    首页</span>
                </router-link>
                >  
                <span style="margin: 0 6px" @click="Jump('/goodsActivity')">玩转条子泥</span>
                >  
                <span style="margin-left:6px;color:#509AB1">{{activeName}}</span>
            </div>
            <el-tabs v-model="activeName"  style="width: 100%;" >
                 <!-- <el-tab-pane label="用户管理" name="first">精品活动</el-tab-pane>
                  <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane> -->


                <el-tab-pane 
                    v-for="(item,index) in imageTitleList" :key="index" :name="item.catalogueName"
                >
                    <span slot="label" v-if="item.catalogueName == '精品活动'">
                        <el-dropdown @command="handleCommand($event,item.picType)" trigger="click">
                            <span class="el-dropdown-link">
                                {{item.catalogueName}}
                            </span>
                            <el-dropdown-menu :style="{width: '191px','border':'none !important','background-color':'#fffce9 !important'} " slot="dropdown">
                                <el-dropdown-item :style="{background:'url(' + require('../../static/dropdownitembg.png') + ')','background-repeat':'no-repeat','background-size': 'cover','width':'130px','margin':'0 auto','font-size':'13px','color':'#5098AF !important' ,'font-weight':'bold','text-align':'center','margin':'10px'} " v-for="i in activeDirectoryList" :key="i.actName" :command="i">{{i.actName}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </span>
                    <span slot="label" @click="handleCommand({id:'402880447bcd46cd017bd4ce0f6d4ca6'},0)" v-if="item.catalogueName == '其他活动'">
                            <span class="el-dropdown-link">
                                其他活动
                            </span>
                    </span>
                    <p v-if="item.catalogueName == '其他活动'" class="image">{{"其他活动"}}</p>
                    <p v-else class="image">{{imageTitle || '精品活动'}}</p>
                    <div v-if=" imageTitle == '聚焦条子泥·摄影欢乐会' && index === 0 " class="tabs">
                        <div
                        @click="tabsListApi(2,'402880447baab07f017baac4d5fc00bb')"
                        :class="activeNum ? 'tabsB' : ''"
                        >
                        往期作品
                        </div>
                        <div
                        @click="tabsListApi(1,'402880447baab07f017baac4d5fc00bb',0)"
                        :class="activeNum ? '' : 'tabsB'"
                        >
                        现场采风
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="content-item" v-for="item in viewMoreList" :key="item.id">
                            <div class="bird-box"  
                                :style="{
                                'background-image': 'url(' + item.picUrl + ')',}"
                            >
                                <img src="~static/scenery/brid-box.png" style="opacity:0.7" alt="">
                            </div>
                            <p class="author">作者：{{item.picAuthor}}</p>
                        </div>
                    </div>
                    <el-pagination
                        background
                        prev-text="首页"
                        next-text="下一页"
                        @prev-click = "prevClick"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="12"
                        layout="prev, pager, next, slot"
                        :total="count"
                        >
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
  
<script>
import { slideshow } from '@/api/test/test'
import { tzn_tplbAPI } from '@/api/test/scenery'
import * as api from "@/api/test/test";
export default {
    name:'SceneryMore',
    components:{},
    data(){
        return {
            slideshowList:[],
            imageTitleList:[{catalogueName:'精品活动',picType:0},{catalogueName:'其他活动',picType:0}],
            activeName:'精品活动',
            imageTitle:this.$route.query.name,
            activeDirectoryList:[
                {actName:"聚焦条子泥·摄影欢乐会",id:'402880447baab07f017baac4d5fc00bb'},
                {actName:"滩玩儿",id:'402880447baab07f017baac511bd00c0'},
                {actName:"英语研学科普",id:'402880447baab07f017baac5931200c6'},
                {actName:"铁人三项",id:'402880447baab07f017baac5d7dc00cf'},
                {actName:"寻找勺嘴鹬",id:'402880447c7ea080017c8297c09f7b27'},
                // {actName:"其他精品活动",id:'402880447bcd46cd017bd4ce0f6d4ca6'},
            ],
            // 分页
            currentPage: 1,
            viewMoreList:``,
            count:0,
            activeNum: true,
            activeId:``,
            picType:1
        }
    },
    created(){

    },
    mounted(){
        if(this.$route.query.name == '其他活动'){
            this.activeName = '其他活动'
        }
        if( this.$route.query.id == '402880447baab07f017baac4d5fc00bb' ){
            this.tabsListApi(2,this.$route.query.id)
        }else{
            this.tabsListApi(0,this.$route.query.id)
        }
        //头部轮播图
        slideshow().then(res => {
            this.slideshowList = res
        })
        // api.activeDirectory().then( res => {
        //     // this.activeDirectoryList = res
        // })
    },
    methods:{
        handleCommand(command,e) {
            console.log(command,e,2231);
            this.imageTitle = command.actName
            if(command.actName == "聚焦条子泥·摄影欢乐会"){
                this.tabsListApi(2 ,command.id)
                this.picType = 2
            }else{
                this.currentPage = 1
                this.picType = 0
                this.tabsListApi(e ,command.id)
            }
        },
        tabsListApi(e = this.picType, activeId, page = this.currentPage - 1){
            if(activeId){
                this.activeId = activeId
            }
            e == 1 ?  this.activeNum = true : this.activeNum = false
            this.picType = e
            console.log(activeId,page,21122);
            api.layUIPage({
                isUse : "1" , activeId: this.activeId, picType : e , size : 12 , page
            }).then(({data,count}) => {
              this.viewMoreList = data
              this.count = count
            });
        },
        //分页
        prevClick(val){
            this.currentPage = 1
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.tabsListApi()
        },
        //头部轮播图跳转
        handleGo(jumpPath){
        // this.$router.push(jumpPath)
        window.location.href = jumpPath
        },
        //数据格式化
        decodeHTML(str) {
            var s = "";
            if (!str || str.length == 0) return "";
                s = str.replace(/&amp;/g, "&");
                s = s.replace(/&lt;/g, "<");
                s = s.replace(/&gt;/g, ">");
                s = s.replace(/&nbsp;/g, " ");
                s = s.replace(/&#39;/g, "\'");
                s = s.replace(/&quot;/g, "\"");
                return s;
        }
    },
}
</script>

<style scoped>
>>>.el-dropdown-link{
    color: #000;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("~static/tab.png") no-repeat;
}
.tabsB {
  background: url("~static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.bird-box{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bird-box>img{
    display: block;
    width: 100%;
    height: auto;
}
.scenery-more{
    background: url('../../static/scenery/backgd.png') ;
}
/deep/.el-image{
    width: 100%;
}
/deep/ .el-carousel__indicators{
    bottom: 5%;
}
.debris{
    width: 100%;
    margin-top: -157px;
    z-index: 8;
    background: transparent;
    position: relative;
}
.title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 92px;
    background-image: url('../../static/scenery/box-border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: left;
    line-height: 92px;
    font-size: 24px;
    letter-spacing: 5px;
}
.home-icon{
    width: 20px;
    height: 20px;
    margin: 0 16px 0 53px;
}
.title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.image{
    width: 452px;
    height: 250px;
    background-image: url('../../static/scenery/img-box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 250px;
    color: #509AB1;
    margin: 55px auto;
    letter-spacing: 1px;
    font-size: 27px;
    color: #509AB1;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
/deep/ .el-tabs__nav-wrap{
    overflow: visible;
}
/deep/ .el-tabs__nav{
    position: absolute;
    top: -70px;
    right: 3%;
}
/deep/ .el-tabs__item{
    background-image: url('../../static/viewTabsAC.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #F6D397;
    margin: 0 15px;
    box-sizing: border-box;
    padding: 0 30px !important;
    height: 45px;
    line-height: 38px;
    font-size: 24px;
}
/* /deep/ .el-tabs__item:nth-of-type(2){
    margin-left: 190px;
}
/deep/ .el-tabs__item:nth-of-type(4){
    margin-right: 190px;
} */
/deep/ .el-tabs__item.is-active{
    background-image: url('../../static/viewTabs.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #5CA0B3;
}
/deep/ .el-tabs__active-bar{
    display: none;
}
/deep/ .el-tabs__nav-wrap::after{
    display: none;
}
.content-box{
    width: 65%;
    margin: 0 auto;
    column-count: 3;
    column-gap: 30px;
}
.content-item{
    margin: 8px;
    width: 28%;
    cursor: pointer;
}
.content-box{
    width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 50px;
}
.box-border1{
    width: 100%;
    height: 100%;
    background-image: url('../../static/scenery/473.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
}
.box-border2{
    width: 100%;
    height: 100%;
    background-image: url('../../static/scenery/473.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
}
.box-border3{
    width: 100%;
    height: 100%;
    background-image: url('../../static/scenery/473.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
}
.box-border4{
    width: 100%;
    height: 100%;
    background-image: url('../../static/scenery/473.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
}
.author{
    width: 100%;
    height: 79px;
    background-image: url('../../static/scenery/author.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 15px 0 0 49px;
    font-size: 18px;
    font-weight: bold;
    color: #509AB1;
}
.el-pagination{
    display: flex;
    justify-content: center;
    margin: 18px 0 51px;
}
/deep/ .el-pagination button, .el-pagination span{
    font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #509AB1;
}
/deep/ .el-pagination button:hover{
    color: #509AB1;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #509AB1;
}
</style>
  